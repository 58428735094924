:root {
  --dark: #23030f;
  --light: #ffb2dc;
  --highlight: #e5017d;
  --very-light: #ffb2dc3f;
  --grey: #908a8a;
  --light-grey: #908a8a26;
  --border-radius: 4px;

  --box-shadow: 6px 6px 45px -15px #00000066;
}

html,
body {
  color: #333;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.error {
  background-color: var(--light);
  padding: 5px 10px;
  display: inline-block;
}
tr {
  width: 100%;
  display: table;
  position: relative;
}

th:first-of-type,
td:first-of-type {
  width: 10%;
}
.font-normal {
  font-weight: normal;
}
.font-bold {
  font-weight: bold;
}

th:last-of-type,
td:last-of-type {
  width: 120px;
  float: right;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;
}

td:last-of-type button,
td:last-of-type a,
.tableActionButton {
  background: none;
  border: none;
  color: var(--grey);
  margin-left: 1rem;
  border-radius: var(--border-radius);
}
td:last-of-type button:hover,
td:last-of-type a:hover,
.tableActionButton:hover {
  color: var(--dark);
}

th,
td {
  width: 20%;
  padding: 10px;
}
a {
  color: inherit;
  text-decoration: none;
}

table a {
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

.page {
  border-top: 4px solid var(--highlight);
  padding-top: 3rem;
}
.header {
  border-top: 4px solid var(--highlight);
  padding: 2rem;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(1400px - 4rem);
  margin: auto;
}
.header a {
  margin-left: 1rem;
  transition: opacity 0.2s ease-in-out;
}
.header a.linkie {
  opacity: 0.5;
}
.header a:hover {
  opacity: 1;
}
.preview {
  border-radius: 4px;
  height: 50px;
  border: 1px solid #33333317;
}
.image-button {
  display: flex;
  flex-direction: column;
}
th {
  text-align: left;
}
.header nav ul {
  display: flex;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#sizes {
  padding: 1rem;
  max-height: 500px;
  overflow: auto;
}

select,
input {
  height: 35px;
  border: 1px solid var(--light-grey);
  border-radius: 3px;
  text-indent: 10px;
  min-width: 200px;
}

input[type="checkbox"] {
  min-width: initial;
  border-radius: 4px;
}

.checkie {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.checkie input {
  margin-right: 1rem;
}
#upload-label:active,
select:focus,
textarea:focus,
a:focus,
button:focus,
input:focus {
  transition: outline-offset 200ms ease;
  outline: max(3px, 0.15em) solid var(--highlight);
  outline-offset: max(3px, 0.15em);
}

button {
  cursor: pointer;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.primary-button {
  background: var(--highlight);
  border: 1px solid var(--highlight);
  border-radius: 3px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 300;
  min-height: 35px;
}

.secondary-button {
  background: none;
  font-weight: 300;
  border: 1px solid var(--highlight);
  border-radius: 3px;
  color: var(--dark);
  display: inline-flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  padding: 5px 20px;
  min-height: 35px;
}
.savedFilters{
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding-inline-end: 20px;

}
.savedFilters .badge{
  padding: 3px 12px;
  font-size: 12px;
  background-color: var(--grey);
  color: white;
  margin-right: 10px;
  border-radius: 10px;
}
.savedFilters button{
  border: none;
  margin-inline-start: 10px;
  background:none;
  display: inline-flex;
  border-radius: 100%;
  padding: 2px;
}
.savedFilters button svg {
  pointer-events: none;
}
.table {
  margin-top: 3rem;
  width: 100%;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-size: 14px;
  box-shadow: var(--box-shadow);
}
thead {
  display: block;
  font-size: 14px;
  border-bottom: 1px solid var(--light-grey);
}
tbody {
  font-weight: 300;
}
thead th {
  font-weight: 200;
}
tr:nth-child(even) {
  background: var(--very-light);
}
tr:nth-child(odd) {
  background: #fff;
}
.container {
  padding: 0 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.form,
form {
  max-width: 500px;
  margin: 0 auto;
}
.add-organizer h2 {
  font-weight: 200;
  margin-bottom: 2rem;
}
button {
  font: inherit;
}
h1 {
  font-weight: 200;
}
label {
  font-weight: 200;
  margin-bottom: 5px;
}
.form-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mr2 {
  margin-right: 2rem;
}
.mr1 {
  margin-right: 1rem;
}
.mt2 {
  margin-top: 2rem;
}
.mt1 {
  margin-top: 1rem;
}

a {
  border-radius: 3px;
}
.sendMail svg {
  margin-left: 1rem;
  color: #777;
}
.gap2 {
  gap: 2rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .inner {
  width: 500px;
  background: #fff;
  padding: 2rem;
  border-radius: 3px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain;
  max-height: 80vh;
  overflow-y: scroll;
}
.inner.large {
  width: 1000px;
  max-height: 90vh;
  overflow-y: scroll;
  display: block;
}
.actionButton {
  display: flex;
  align-items: center;
  background: white;
  cursor: pointer;
  padding: 5px 15px;
  border: 1px solid var(--dark);
  border-radius: 30px;
  margin-top: 4px;
}
.imageList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  min-width: 250px;
}
.imageList li {
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.imageList ul {
  list-style: none;
}
.imageList img {
  border-radius: var(--border-radius);
}
.emptyImage {
  height: 30px;
  width: 30px;
  display: inline-flex;
  background-color: var(--very-light);
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
}
.imagesToUpload {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.imagesToUpload button {
  background: none;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
}

#afbeelding {
  opacity: 0;
  height: 0;
}

#upload-label {
  /* border: 1px solid var(--highlight); */
  align-items: center;
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
  text-align: center;
  border-radius: 3px;
  padding: 5px 10px;
  /* width: 100%; */
}
#upload-label span {
  width: 100%;
}
#upload-label svg {
  margin-top: 6px;
  color: var(--dark);
  width: 100%;
}
.w100 {
  width: 100%;
}
#upload-label:hover svg {
  color: var(--highlight);
}

.inner.highlight {
  background: var(--light);
}

#canvas,
#canvas2 {
  border: 1px dashed var(--light);
}

#canvas2 {
  position: absolute;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centerText,
.summary {
  text-align: center;
}

a.active {
  opacity: 1;
  /* text-decoration: underline; */
  pointer-events: none;
}

/* START CHECKBOX */

.alter-expo-checkbox {
  font-size: 18px;
  font-weight: 500;
  display: grid;
  width: 100%;
  grid-template-columns: 1em auto;
  gap: 14px;
  line-height: 1.1;
  height: 100%;
  align-content: center;
  align-items: center;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: var(--form-fill-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid currentColor;
  border-radius: 0.2em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid var(--focus-color);
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.alter-expo-checkbox--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.labelWrapper {
  display: flex;
  align-items: center;
}

.labelWrapper i {
  margin-right: 7px;
}

.labelWrapper a {
  text-decoration: underline;
  padding-inline: 1ch;
}
.labelWrapper svg {
  margin-right: 10px;
  transform: translateY(-3px);
  width: 20px;

  height: 20px;
}
.labelWrapper.small {
  font-size: 12px;
}

.labelWrapper span.dot {
  height: 8px;
  min-width: 8px;
  border-radius: 8px;
  margin-left: 5px;
}

/* END CHECKBOX */

.date {
  display: inline-flex;
  margin-bottom: 1rem;
  padding: 4px 10px;
  border-radius: 10px;
  font-family: monospace;
  background-color: var(--very-light);
}

.reminder {
  line-height: 1.8;
}

.reminder button {
  background: none;
  border: none;
  color: var(--highlight);
  font-weight: 600;
  cursor: pointer;
}

.adminBackButton {
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.5;
  transition: opacity 300ms ease;
}
.adminBackButton:hover {
  opacity: 1;
}

.extraInfoContainer {
  background-color: var(--light-grey);
  padding: 3rem;
  width: fit-content;
}
.extraInfoContainer a {
  text-decoration: underline;
}
.extraInfoContainer ul {
  list-style: initial;
  margin-block-start: 1em;
  padding-inline-start: 40px;
}
.helpButton {
  background: none;
  font-size: 2rem;
  border: none;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  color: var(--grey);
  transition: color 250ms ease;
}

.helpButton:hover {
  color: var(--dark);
}
.helpImage {
  width: 80vw;
}

/* BEGIN LOADER */
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.749);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 53px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--highlight);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* END LOADER */

.sendMail {
  text-decoration: underline;
}

.print {
  display: none;
}

.listButtons {
  list-style-type: disc;
  padding-inline-start: 20px;
  margin-top: 1rem;
}
@media print {
  .noprint {
    display: none;
  }
  .print {
    display: block;
  }

  .printtable tr {
    background-color: white !important;
  }
  * {
    box-shadow: none !important;
  }
}
